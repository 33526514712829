import { Resource } from 'i18next';

const nl: Resource = {
  translation: {
    footer: {
      cookiePreferences: 'Cookie preferences',
      allRightsReserved: '© 2024 Intake BV — All rights reserved',
      feelingGood: 'yoboo - Feeling good, driven by you',
    },
    welcome: {
      title: 'Benieuwd naar je <span class="gradient-yellow">energieniveau</span>?',
      headTitle: 'Je gezicht is de spiegel van je energie!',
      description: "Je gezicht weerspiegelt hoe het met jou en je gezondheid gaat. Benieuwd? Met onze korte en eenvoudige scan krijg je meer inzicht in drie belangrijke energiebronnen: beweging, rust en voeding.",
      startButton: 'Start de face scan',
      infoButton: 'Instructievideo',
    },
    profile: {
      error: 'Gelieve alle vragen te beantwoorden om door te gaan',
      step0: {
        title: 'Je bent bijna klaar om de scan te starten',
        description: 'Vul nog enkele korte aanvullende vragen in, zodat je resultaten je energieniveau correct weerspiegelen. Dit vraagt slechts enkele seconden van je tijd.',
        button: 'Ga naar de vragen',
      },
      step1: {
        progressText: 'Vraag 1 van 4',
        title: 'Enkele algemene vragen...',
        button: 'Volgende vragen',
        age: 'Wat is je leeftijd? *',
        man: 'Man',
        woman: 'Vrouw',
        gender: 'Wat was je geslacht bij geboorte?',
        smoker: 'Rook je?',
        yes: 'Ja',
        no: 'Nee',
      },
      step2: {
        progressText: 'Vraag 2 van 4',
        title: 'Enkele vragen over je fysieke kenmerken...',
        button: 'Volgende vragen',
        height: 'Wat is je lengte (in cm)? *',
        weight: 'Wat is je gewicht (in kg)? *',
      },
      step3: {
        progressText: 'Vraag 3 van 4',
        title: 'Enkele medische vragen...',
        info: 'We zijn er bijna!',
        button: 'Volgende vragen',
        hypertension: 'Heb je last van hoge bloeddruk?',
        diabetic: 'Ben je een diabetespatiënt?',
        medication: 'Neem je een of meerdere geneesmiddelen voor hoge bloeddruk?',
        yes: 'Ja',
        no: 'Nee',
      },
      step4: {
        progressText: 'Vraag 4 van 4',
        title: 'Je contactgegevens...',
        info: 'zodat we je de resultaten kunnen sturen.',
        button: 'Start de face scan',
        email: 'E-mailadres *',
        firstname: 'Voornaam *',
        lastname: 'Achternaam *',
      },
      step5: {
        title: 'Nog enkele checks...',
        info: 'Hierna start de scan',
        button: 'Start de face scan',
        list: {
          item0: 'Controleer nog even het volgende:',
          item1: '1. Selecteer de juiste camera',
          item2: '2. Geef <strong>toestemming</strong> aan je browser',
          item3: '3. Positioneer je toestel op <strong>10-15 cm</strong> voor je gezicht',
          item4: '4. Zorg dat je gezicht <strong>de hele cirkel</strong> vult',
          item5: '5. Zorg dat de <strong>cirkel goed verlicht</strong> en duidelijk zichtbaar is',
          item6: '6. <strong>Probeer niet te bewegen of te praten</strong> zodra de scan start. Deze <strong>duurt 30 seconden</strong>',
        },
        cookieText: 'Ik ga akkoord met de',
        cookieLink: 'algemene voorwaarden',
      },
      step6: {
        title: 'Algemene voorwaarden',
        introduction: {
          p1: 'De bijzondere algemene voorwaarden Yoboo-mirror (hierna: “ bijzondere algemene voorwaarden Yoboo”) regelen de relatie tussen de gebruiker voor de verschillende Yoboo services (afgekort “Yoboo services”) en Intake BV. Door de Yoboo services te raadplegen of op eender welke wijze te gebruiken, aanvaardt de gebruiker dat de bijzondere algemene voorwaarden Yoboo van toepassing zijn.',
          p2: 'De bijzondere voorwaarden, ter kennis gebracht aan elke gebruiker en door hem/haar aanvaard door het gebruik van de Yoboo services, blijven onverminderd van toepassing, inclusief de wijzigingen daaraan die in overeenstemming met het punt “geldigheid en wijziging van de voorwaarden” van de bijzondere algemene voorwaarden werden doorgevoerd. De meest recente versie is steeds op de website terug te vinden (te raadplegen op: <a href="https://www.yoboo.be/nl/legaal/algemene-voorwaarden" target="_blank">https://www.yoboo.be/nl/legaal/algemene-voorwaarden</a>). Indien de gebruiker niet (meer) akkoord gaat met de toepassing van één of meer van de bepalingen vermeld in de bijzondere algemene voorwaarden yoboo, is een verdere raadpleging en/of een verder gebruik van de Yoboo services niet meer mogelijk.',
        },
        identity: {
          title: 'Identiteit uitgever en beheerder Yoboo',
          p1: '<strong>Intake BV</strong>: Onderneming met maatschappelijke zetel te B-2018 Antwerpen, Quellinstraat 12 bus 6',
          p2: '<strong>Btw</strong>: BE0767-722-633 RPR Brussel',
          p3: '<strong>Klantendienst</strong>: via contactformulier op de yoboo-website.<br />Of per post Quellinstraat 12 bus 6, 2018 Antwerpen'
        },
        definitions: {
          title: 'Definities',
          p1: '<strong>Gebruiker</strong> hierna genoemd “de gebruiker”: de gebruiker is een meerderjarige natuurlijke persoon die van de Yoboo-services gebruik maakt. Het is de gebruiker enkel toegelaten de yoboo-services te gebruiken voor de daartoe voorziene doeleinden.',
          p2: '<strong>Yoboo services</strong>: Hieronder vallen de verschillende services die worden aangeboden via de Yoboo app, het Yoboo portaal of Yoboo website of enig ander medium waarmee Intake Bv zijn Yoboo services nu en in de toekomst ter beschikking stelt.',
        },
        purpose: {
          title: 'Doel van Bijzondere Algemene Voorwaarden',
          p1: 'Met deze bijzondere algemene voorwaarden alsook met de algemene voorwaarden van alle Yoboo services wil Intake Bv de gebruiker de essentiële informatie geven over de rechten en verplichtingen bij het gebruik van de Yoboo-services.',
          p2: 'Verantwoordelijkheden van de gebruiker :',
          list: {
            item1: 'Het gebruik van de Yoboo services is niet toegestaan voor personen onder de 18 jaar.',
            item2: 'Het gebruik van de Yoboo services is enkel mogelijk mits de gebruiker in het bezit is van een activatiecode',
            item3: 'De toegangscode om Yoboo services te activeren is persoonlijk en mag niet doorgegeven worden aan of gebruikt worden door een ander persoon.',
            item4: 'De gebruiker draagt de verantwoordelijkheid om het vertrouwelijk en persoonlijk karakter van zijn/haar accountgegevens te bewaren.',
            item5: 'De gebruiker is zelf verantwoordelijk voor het correct invullen van de gevraagde informatie.',
          },
        },
        general: {
          title: 'Algemene voorwaarden Yoboo',
          p1: 'Registratie: om toegang te krijgen tot de verschillende Yoboo services dient de gebruiker zich te registreren via de Yoboo website.',
          p2: 'Ieder commercieel gebruik van de yoboo services is strikt verboden. Onder commercieel gebruik wordt onder andere begrepen het verkopen of verhuren van de verschillende functionaliteiten van de yoboo services, of het gebruiken van de yoboo services en haar onderdelen met als enig doel het genereren van advertentie- of abonnementsinkomsten, of meer algemeen het organiseren van oneerlijke concurrentie of het stellen van daden in strijd met de eerlijke handelsgebruiken ten opzichte van Intake Bv, of het vermarkten of anderszins hergebruiken van de inhoud, afbeeldingen of enig ander materiaal die via de Yoboo services ter beschikking zijn gesteld. Deze opsomming is niet limitatief.',
          p3: 'De gebruiker blijft als enige verantwoordelijk voor een correct gebruik van Yoboo services. De gebruiker zal Intake Bv integraal vrijwaren voor alle schade en kosten die voortvloeien uit het geven van verkeerde of onvolledige gegevens bij het beheer van Yoboo services of een verkeerd gebruik/misbruik van Yoboo services.',
          p4: 'Door aanvaarding van Bijzondere aanvullende voorwaarden van de verschillende services, zullen de gegevens die de gebruiker heeft ingevuld op de yoboo-website worden gebruikt in de verschillende services en vice versa.',
          p5: "Alle gegevens die van de gebruiker worden bekomen door gebruik te maken van de Yoboo-mirror door BV Intake worden niet bijgehouden en worden na gebruik onmiddellijk verwijderd. De persoonsgebonden gegevens van de gebruiker zullen overeenkomstig het privacy beleid van de BV Intake verwerkt worden. Bij het gebruik van de Yoboo-mirror worden enkel de naam en het mail adres bewaard door Intake BV.",
          p6: 'Het resultaat van het gebruik van de services word per mail verstuurd.',
        },
        website: {
          title: 'Website gebruik:',
          p1: "De gebruiker is bekend met de beperkingen en de risico's van het gebruik van internet of enig ander medium waarmee Intake bv zijn diensten nu en in de toekomst ter beschikking stelt. Ook is de gebruiker bekend met de risico’s van het digitaal of elektronisch opslaan en overbrengen van informatie. De gebruiker aanvaardt dat Intake Bv niet aansprakelijk is voor enige schade door het gebruik van hun websites of het internet als gevolg van voormelde risico’s.  Intake Bv kan niet aansprakelijk worden gesteld voor de inhoud van websites of links op hun website beschikbaar zijn gesteld. Het loutere feit dat deze links beschikbaar zijn op de website van Intake Bv houdt op geen enkel moment enige goedkeuring, aanvaarding of erkenning in van (de inhoud van) die andere website. Bovendien is het de eigen verantwoordelijkheid van de gebruiker om te controleren of de website vrij zijn van virussen en andere programma’s of zaken die schadelijk zouden kunnen zijn voor zijn apparatuur. De gebruiker moet steeds de toepasselijke voorwaarden en/of disclaimers raadplegen van deze andere website/apps vrij zijn van virussen en andere programma’s  of zaken die schadelijk zouden kunnen zijn voor zijn apparatuur. De gebruiker moet steeds de toepasselijke voorwaarden en/of disclaimers raadplegen van deze andere website/apps en desbetreffend goedkeuren.",
          p2: 'De gebruiker is verplicht regelmatig na te gaan of de meest recente versie van de Yoboo website is geïnstalleerd opdat hij over de meest recente informatie zou beschikken. Intake Bv is in geen geval aansprakelijk voor beslissingen, van welke aard ook, genomen door de gebruiker op basis van de verkregen informatie via de app.',
        },
        liability: {
          title: 'Aansprakelijkheid Intake Bv:',
          p1: 'De algemene voorwaarden, disclaimers en andere voorwaarden blijven naast de algemene voorwaarden van de andere Yoboo services onverminderd van toepassing.',
          p2: 'Hoewel de Yoboo services uiterste zorgvuldigheid in acht nemen, is het mogelijk dat informatie onvolledig, niet correct, niet geschikt voor een specifieke situatie of niet volledig of up-to-date is.',
          p3: 'Behoudens in geval van schade voortvloeiend uit bedrog, opzet of grove schuld van Intake Bv, haar aangestelde of lasthebbers, zal Intake Bv en/of haar bestuurders, aandeelhouders, agenten en vertegenwoordigers niet aansprakelijk kunnen worden gesteld voor enige rechtstreekse, onrechtstreekse, incidentele of gevolgschade.',
          p4: 'De contractuele en buitencontractuele aansprakelijkheid van Intake Bv en haar bestuurders, aandeelhouders, agenten en vertegenwoordigers zal ten allen tijde beperkt zijn tot de bedragen die gedekt worden door de aansprakelijkheidsverzekering die door Intake Bv werd afgesloten. Deze dekking bedraagt maximaal 20.000 euro per schadegeval maar is beperkt tot 20.000 euro per jaar voor alle schadegevallen samen.',
          p5: 'De voorgaande beperkingen van aansprakelijkheid gelden niet voor de wettelijke aansprakelijkheid van Intake Bv en haar bestuurders, aandeelhouders, agenten en vertegenwoordigers bij overlijden of lichamelijk letsel van de consument ten gevolge van hun doen of nalaten.',
          p6: 'Yoboo services kunnen niet gekwalificeerd worden als services dienstig voor medische doeleinden. De services zijn geen medisch advies, diagnose of behandeling en zijn niet bedoeld voor medisch gebruik of inzicht. De services zijn erop gericht de gebruiker inzicht te geven in hun levensstijl en inspiratierichtlijnen te geven voor een verbetering van hun levensstijl. Het inzicht in de gezondheid zijn gegevens van fysiologische en psychologische aard, maar kunnen niet beschouwd worden als medisch advies. De inzichten van Yoboo services zijn niet bedoeld om gebruikt te worden voor medische doeleinden of voor het diagnosticeren, behandelen, genezen of voorkomen van een ziekte, aandoening of verwonding. De inzichten komen niet in de plaats van het advies of diagnose van een arts of gelijkgestelde en / of een consult bij een arts of andere medische gelijkgestelde.',
          p7: 'De gegevens bekomen door toepassing van de Yoboo-mirror worden verwerkt volgens een geautomatiseerde besluitvorming.',
        },
        access: {
          title: 'Toegang en gebruik van de Yoboo services:',
          p1: 'Het is strikt verboden om, op welke wijze dan ook en met welke technische middelen dan ook, zich toegang trachten te verschaffen tot delen die niet publiekelijk zijn geplaatst. Het is tevens strikt verboden de Yoboo services te wijzigen, aan te passen, zaken eraan toe te voegen of te verwijderen, op welke wijze dan ook en met welke technische middelen dan ook, met uitsluiting van de mogelijkheden en ruimtes die daar voor zijn voorzien.',
          p2: 'De gebruiker verbindt zich ertoe om geen enkele daad te stellen, ongeacht de gebruikte technische middelen, die op enig ogenblik de goede werking van de Yoboo services op welke wijze dan ook in het gedrang kan brengen.',
        },
        validity: {
          title: 'Geldigheid en wijziging van de voorwaarden:',
          p1: 'Indien een bepaling uit de bijzondere algemene voorwaarden Yoboo nietig, ongeldig, niet afdwingbaar of onwettig wordt bevonden, blijven de overige bepalingen onverminderd van kracht. De leemte die ontstaat in de bijzondere algemene voorwaarden Yoboo, als gevolg van de nietige, ongeldige, niet afdwingbare of onwettige bepaling zal conform de geldende wetgeving worden opgevangen door de toepassing van een geldige bepaling die het oorspronkelijke economische opzet van de voorwaarden het dichtst benadert.',
          p2: 'Het feit dat Yoboo nalaat de strikte toepassing van één of meerdere van de bepalingen van deze voorwaarden te eisen, kan niet worden beschouwd als een stilzwijgende afstand van de rechten waarover zij beschikt en verhindert niet om later de strikte naleving ervan te eisen.',
          p3: 'Intake Bv behoudt zich ten allen tijde het recht voor om wijzigingen in voorliggende bijzondere algemene voorwaarden Yoboo aan te brengen. Bij een wijziging van de bijzondere algemene voorwaarden Yoboo, zal de gebruiker hiervan steeds op de hoogte worden gebracht en zal worden gevraagd deze opnieuw te aanvaarden indien hij/zij verder gebruik wenst te maken van de Yoboo services.',
          p4: '<strong>Intellectuele eigendomsrechten</strong>',
          p5: 'Het concept, de inhoud, de lay-out, de structuur, de sourcecodes, de programmatuur, de afbeeldingen, de foto’s , de recepten, de animaties, de audiovisuele werken, de teksten, gegevens, muziek en alle andere onderdelen van de Yoboo services zijn en blijven de exclusieve eigendom van Intake Bv of van de partners waar Intake Bv mee samenwerkt en worden beschermd door diverse intellectuele eigendomsrechten (waaronder het auteursrecht, het merkenrecht, het softwarerecht, het modelrecht, het octrooirecht, het databankenrecht, het sui generis recht van de producent van een databank, en/of andere intellectuele eigendomsrechten of exclusiviteitsrechten) hetgeen door de gebruiker wordt erkend en aanvaard. Door de Yoboo services te gebruiken, verkrijgt de gebruiker daarop geen enkel recht.',
          p6: "De gehele of gedeeltelijke reproductie, verspreiding, verkoop, verdeling, publicatie, aanpassing en/of gebruik, onder welke vorm dan ook, op eender welke wijze van de Yoboo website en zijn inhoud is strikt verboden. Het opslaan van enige informatie en/of onderdeel van de app in een (elektronische) database of anderszins is niet toegestaan. Door bepaalde gegevens, teksten, afbeeldingen of andere bestanden online te plaatsen, geeft de gebruiker automatisch aan Intake Bv kosteloos de toestemming om de inhoud van de post wereldwijd te reproduceren, mee te delen of anderszins te gebruiken zowel in de app, als in enige andere publicitaire of commerciële boodschap. De gebruiker maakt zich sterk dat hij bekwaam is om deze toestemming te geven en, bijvoorbeeld in het geval van auteursrechtelijk beschermde werken, over de toestemming van de auteur beschikt.",
        },
        questions: {
          title: 'Vragen of klachten:',
          p1: 'De gebruiker kan voor vragen of klachten in eerste instantie door het aanwenden van een contactformulier op de yoboo-website. Komt er alsnog geen verzoening, dan zal in wederzijds overleg een bemiddeling worden opgestart. Indien deze bemiddeling geen oplossing brengt zal bij verder conflict het geschil worden voorgelegd aan de bevoegde rechtbanken van België en onderworpen worden aan het Belgische recht.',
          p2: 'Woont de klant niet in België, dan kan hij/zij eveneens in eerste instantie bij de klantendienst terecht. Wanneer de klant daar geen oplossing voor het conflict bereikt, kan hij/zij voor online kwesties ook bij het Europees Online Dispute Resolution Platform (<a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>) terecht in zijn/haar taal.',
        },
        privacy: {
          title: 'Privacy bescherming van persoonsgegevens :',
          p1: "Het verlenen van de diensten zoals omschreven binnen deze algemene voorwaarden vereist de inzameling en verwerking van de persoonsgegevens van de gebruiker. De bescherming van de persoonsgegevens van de gebruiker is uitermate belangrijk voor Intake Bv. Intake Bv maakt er dan ook een punt van om deze zo goed als mogelijk te beschermen en bij het gebruik van deze gegevens altijd de rechten van de gebruiker te waarborgen in overeenstemming met de toepasselijke regels inzake privacy en bescherming van persoonsgegevens.",
          p2: "Intake Bv verwerkt de persoonsgegevens van de gebruiker voor doeleinden en rechtsgronden beschreven in de Privacyverklaring. De Privacyverklaring is online te raadplegen of kan worden opgevraagd via de klantendienst. Deze Privacyverklaring kan van tijd tot tijd aangepast worden.",
          p3: 'De gebruiker verbindt zich ertoe de klantendienst tijdig op de hoogte brengen van elke wijziging met betrekking tot zijn gebruikelijke e-mailadres, identificatie- en adresgegevens teneinde Intake Bv toe te staan een correcte dienstverlening te bieden.',
        },
        conflicts: {
          title: 'Tegenstrijdigheden:',
          p1: 'In geval van tegenstrijdigheid tussen deze algemene voorwaarden en de Bijzondere aanvullende voorwaarden van de specifieke Yoboo services, primeren de Bijzondere aanvullende voorwaarden op de algemene voorwaarden.',
        }
      },
    },
    details: {
      title: 'Laten we aan de slag gaan!',
      step1: {
        title: 'Camera toegang',
        description: 'Op het <strong>volgende scherm</strong>, wanneer je de scan start, moet je <strong>toestemming\n geven aan je browser</strong> om de metingen uit te kunnen voeren.',
        imageText: 'Dit ziet er zo uit:',
      },
      step2: {
        title: 'Positioneer je toestel',
        list: {
          item1: 'Houd je toestel<strong>10-15 cm voor je gezicht</strong>.',
          item2: 'Je gezicht de hele cirkel vult.',
          item3: 'De cirkel goed verlicht en duidelijk zichtbaar is.',
        }
      },
      step3: {
        title: 'De scan uitvoeren',
        list: {
          item1: 'De <strong>scan start automatisch</strong> wanneer de <strong class="green-text-details">groene cirkels</strong> beginnen te draaien.',
          item2: 'Houd je <strong>gezicht in het midden</strong>, dicht bij de camera en houd het stabiel.',
          item3: 'Probeer <strong>niet te bewegen of te praten</strong>.'
        }
      },
      button: 'De scan uitvoeren!',
    },
    results: {
      results_done: {
        title: 'De scan is voltooid, super!',
        description: 'Jouw weg naar energie is slechts één klik verwijderd! Via <strong>email</strong> ontvang je ook je resultaten en kan je verder aan de slag.',
        button: 'Bekijk je resultaten',
      },
      navbarButton: {
        energy: 'Laat de cijfers maar zien!',
        measurements: 'Terug naar energierapport',
      },
      error: {
        title: 'Kan de scan niet starten',
        description: 'Uw wijzigingen zijn opgeslagen, maar we konden de scan niet starten vanwege een technisch probleem aan onze kant. Probeer het opnieuw.',
        button: 'Probeer opnieuw',
      },
      header: {
        energy: {
          title: 'Jouw energierapport',
          description: 'Ontdek hoe jij scoort op de <strong>energiebronnen</strong> beweging, rust en voeding. Hoe voller je batterij, hoe meer je energiebron in balans is, hoe energieker jij je voelt.',
          info: 'Jouw gegevens worden vertrouwelijk behandeld en niet met anderen gedeeld.',
        },
        measurements: {
          title: 'Inzichten op basis van de scan',
          description: "Een overzicht van de metingen en daarbijhorende risico's.",
        },
      },
      measurementsTableHeader1: {
        value: 'Waarde',
        name: 'Meting',
      },
      measurementsTableHeader2: {
        value: 'Percentage',
        name: 'Risico',
      },
      measurements: {
        title1: 'Metingen',
        title2: "Risico's",
        HR_BPM: 'Hartslag (per minuut)',
        IHB_COUNT: 'Aantal onregelmatige hartslagen',
        BR_BPM_STRICT: 'Ademhalingssnelheid (per minuut)',
        BP_SYSTOLIC: 'Systolische bloeddruk (mmHg)',
        BP_DIASTOLIC: 'Diastolische bloeddruk (mmHg)',
        HRV_SDNN: 'Hartslagvariabiliteit (milieseconden)',
        BP_RPP: 'Hartbelasting (Hartslag x Systolische bloeddruk)',
        BP_TAU: 'Vasculaire capaciteit (seconden)',
        AGE: 'Leeftijd gezichtshuid (jaar)',
        HBA1C_RISK_PROB: "Risico op Hemoglobine A1c",
        DBT_RISK_PROB: 'Risico op diabetes type 2',
        HDLTC_RISK_PROB: "Risico op Hypercholesterolemie",
        HPT_RISK_PROB: "Risico op Hypertensie",
      },
      physical: {
        title: 'Beweging',
        description: 'De batterij weerspiegelt de verhouding tussen bewegen, staan en zitten in je dagelijkse leven. Hoe meer in balans, hoe voller de batterij.',
        measurements: {
          body_mass_index: 'Body Mass Index (10 - 60)',
          facial_skin_age: 'Leeftijd gezichtshuid (13 - 120)',
          waist_to_height_ratio: 'Taille tot lengte verhouding (25 - 70)',
          body_shape_index: 'Lichaamsvorm index (6.19 - 8.83)',
          height: 'Lengte (cm)',
          weight: 'Gewicht (kg)',
          estimated_waist_circumference: 'Geschatte tailleomtrek',
        },
      },
      mental: {
        title: 'Rust',
        description: 'Hoe goed ga jij om met stress en emoties. Hoe sterker je hier in bent, hoe voller de batterij.',
        measurements: {
          mental_stress_index: 'Mentale stressindex (1 - 5,9)'
        },
      },
      metabolic: {
        title: 'Voeding',
        description: 'Zet je lichaam voeding vlot om in energie. Hoe vlotter je lichaam dit kan, hoe voller de batterij.',
        measurements: {
          hypertension_risk: 'Risico op hypertensie (0 - 100%)',
          type_2_diabetes_risk: 'Type 2 Diabetesrisicok (0 - 100%)',
          hypercholesterolemia_risk: 'Risico op hypercholesterolemie (0 - 100%)',
          hypertriglyceridemia_risk: 'Risico op hypertriglyceridemie (0 - 100%)',
        },
      },
      preFooter: {
        title: 'Benieuwd naar de werking achter onze Face Scan?',
        description: 'Je vraagt je misschien af hoe we op basis van een gezichtsscan al deze informatie met jou kunnen delen.<br /><br />Je <strong>gezicht weerspiegelt jouw energieniveaus</strong>. Met de nodige technologie kan je in je gelaat bijvoorbeeld <strong>onderliggende kleurveranderingen</strong> detecteren die meer vertellen over de <strong>bloedstroom</strong> in je gezicht. Dit staat direct in relatie tot je hart en via <strong>verschillende algoritmen</strong> kan zo een realistische waarde van je hartslag, ademhaling en hartslagvariabiliteit bepaald worden.<br /><br />Deze, samen met andere waarden, geven een indicatie rond je energieniveaus. Interessant, hé?',
      },
      footer: {
        title: 'Wil je meer inzichten?',
        description: 'Wil je graag meer inzichten en begeleiding op het vlak van energie en een gezonde leefstijl, op een leuke en toegankelijk manier? Ontdek hier hoe wij je kunnen ondersteunen om je batterijen op te laden.',
        button: 'Scan opnieuw uitvoeren',
      },
    },
    proceed: {
      slide1: {
        title: 'Jouw persoonlijk dashboard',
        description: 'Je ontvangt in je mailbox een uitnodiging om de <strong>Willbeing test</strong> te doen, want bij Yoboo zijn we ervan overtuigd dat je <strong>motivatie het startpunt</strong> is voor een energiekere en gezondere leefstijl. Test ingevuld? Super! De resultaten vind je op je <strong>persoonlijke dashboard</strong>.',
      },
      slide2: {
        title: 'Eén op één coaching',
        description: 'Tijdens een <strong>persoonlijk gesprek</strong> met een yoboo coach, ontvang je <strong>advies en inzichten</strong> over de yoboo-pijlers. We bekijken samen welke <strong>acties</strong> jij kan en wil integreren rond <strong>voeding, beweging en rust</strong>.',
      },
      slide3: {
        title: 'Aan de slag',
        description: 'Ga nu zelf aan de slag op jouw eigen tempo. De yoboo coach geeft je een <strong>actiekaart</strong> en onze <strong>yoboo app</strong> inspireert en motiveert jou in jouw persoonlijk reis.',
      },
    },
  },
};

export default nl;
