import React, { FC } from 'react';
import styles from './styles.module.css';
import Navbar from '../../components/Navbar';
import { t } from 'i18next';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import Step1 from '../../assets/images/step1.png';
import Step2 from '../../assets/images/step2.png';
import Step3 from '../../assets/images/step3.png';
import '@splidejs/react-splide/dist/css/themes/splide-default.min.css';
import { ArrowCarousel } from '../../assets/svg';
import './splide.css';
import { Trans, useTranslation } from 'react-i18next';

export const Proceed: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n } = useTranslation();

    const handleBack = () => {
        window.history.back();
    };

    return (
        <div>
            <Navbar isResults={false} />
            <div className={styles.container}>
                <div className={styles.goBack}>
                    <ArrowCarousel onClick={handleBack} fill="#2E3767" />
                </div>
                <div style={{ width: '100%' }}>
                    <Splide
                        hasTrack={false}
                        options={{
                            type: 'loop',
                            perPage: 1,
                            perMove: 1,
                            pagination: true, // Disable built-in pagination
                            arrows: true, // Disable built-in arrows
                            gap: '16px',
                        }}
                        style={{ width: '100%', position: 'relative' }}
                    >
                        <div className={`splide__arrows ${styles.arrows}`}>
                            <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.arrow_left}`}>
                                <ArrowCarousel />
                            </button>
                            <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.arrow_right}`}>
                                <ArrowCarousel />
                            </button>
                        </div>

                        <ul className={`splide__pagination ${styles.pagination}`}></ul>

                        <SplideTrack>
                            <SplideSlide>
                                <div className={styles.proceed_header}>
                                    <h2 className={styles.title}>{t('proceed.slide1.title')}</h2>
                                    <div className={styles.paragraphs_container}>
                                        <p className={`text-center ${styles.paragraph}`}>
                                            <Trans i18nKey="proceed.slide1.description" />
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.slide_item}>
                                    <img className={styles.item_image} src={Step1} alt="Some text" />
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className={styles.proceed_header}>
                                    <h2 className={styles.title}>{t('proceed.slide2.title')}</h2>
                                    <div className={styles.paragraphs_container}>
                                        <p className={`text-center ${styles.paragraph}`}>
                                            <Trans i18nKey="proceed.slide2.description" />
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.slide_item}>
                                    <img className={`${styles.item_image}  ${styles.item_image_bottom}`} src={Step2} alt="Some text" />
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className={styles.proceed_header}>
                                    <h2 className={styles.title}>{t('proceed.slide3.title')}</h2>
                                    <div className={styles.paragraphs_container}>
                                        <p className={`text-center ${styles.paragraph}`}>
                                            <Trans i18nKey="proceed.slide3.description" />
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.slide_item}>
                                    <img className={styles.item_image} src={Step3} alt="Some text" />
                                </div>
                            </SplideSlide>
                        </SplideTrack>
                    </Splide>
                </div>
            </div>
        </div>
    );
};
