export const NLFlag = ({ width = "100%" }: { width: string }) => {
    return (
        <svg width={width} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_974_34944)">
                <mask id="mask0_974_34944" style={{maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_974_34944)">
                    <path d="M0 7.82827L11.8969 6.92358L24 7.82827V16.172L12.0516 17.6861L0 16.172V7.82827Z" fill="#EEEEEE"/>
                    <path d="M0 0H24V7.82812H0V0Z" fill="#A2001D"/>
                    <path d="M0 16.1719H24V24H0V16.1719Z" fill="#0052B4"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_974_34944">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
