export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvaU7zGe1vYdojMdjbPYv
QavhnImHTorEOQl+wkzNDNwnWxydkr0Oc8kUzUGQ2FV2GAfTxD/3PfiiI8nGP/6x
YYyprwt/3BiHOAcczNNM+veIHHSbtej1O46rwNr5oEla2rBBl2+m3WCRSFhzcyNa
f0qamT6nyOih2CqbIiUEgPpVSfKSs08Lj01Fb/t1XZi5B0BdgHNdRtFw9dO0ctE+
OCsOQtKEI0uE2r7S20z6HGd0nn9V07ruF5A7Xb+K4kDXyPOT9aBEcYOhVu8LcWN+
rmKLuql8xuuMpIkX8ji6iaSezSBlqlDIIM/xFYOqNtv67b6mrkV1t58j1LkqNzNh
YQIDAQAB
-----END PUBLIC KEY-----
`;
