import { FC, MouseEventHandler, useEffect, useState } from 'react';

import Logo from '../../assets/images/logo.png';
import styles from './styles.module.css';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Navbar: FC<{
    isResults?: boolean,
    onlyLogo?: boolean,
    buttonText?: string,
    onClick?: MouseEventHandler
}> = ({ isResults = false, onlyLogo = false, buttonText = '', onClick = () => {} }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const goToProfile = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.navbar}>
            <div className={styles.logo_container}>
                <div onClick={goToProfile}>
                    <img src={Logo} alt="logo" width={screenWidth <= 500 ? '120px' : '200px'} />
                </div>
            </div>
            {!onlyLogo && <div className={styles.actions}>
                {isResults && <div onClick={onClick} style={{ cursor: 'pointer' }}>
                    <span className={styles.text}>{buttonText}</span>
                </div>}
                <LanguageSwitcher />
            </div>}
        </div>
    );
};

export default Navbar;
