export const Battery = ({
    score = 0,
    width = "100%",
    height = "100%"
}: {
    score?: number,
    width?: string|number,
    height?: string|number
}) => {
    let batteryLevel: string[] = [];

    switch (score) {
        case 1:
            batteryLevel = ['#F0F3FF', '#F0F3FF', '#F0F3FF', '#F0F3FF', '#FA9600'];
            break;

        case 2:
            batteryLevel = ['#F0F3FF', '#F0F3FF', '#F0F3FF', '#0E62BE', '#0E62BE'];
            break;

        case 3:
            batteryLevel = ['#F0F3FF', '#F0F3FF', '#68C4FF', '#68C4FF', '#68C4FF'];
            break;

        case 4:
            batteryLevel = ['#F0F3FF', '#9BD154', '#9BD154', '#9BD154', '#9BD154'];
            break;

        case 5:
            batteryLevel = ['#26A749', '#26A749', '#26A749', '#26A749', '#26A749'];
            break;

        default:
            break;
    }

    return (
        <svg width={width} height={height} viewBox="0 0 64 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L20 4C20 1.79086 21.7909 -7.8281e-08 24 -1.74846e-07L40 -8.74228e-07C42.2091 -9.70792e-07 44 1.79086 44 4L44 6L20 6Z"
                fill="#C3C9DB" />
            {batteryLevel.map((item, index) => {
                return (
                    <rect key={index} x="8" y={14 + 21 * index} width="48" height="17" rx="4" fill={item} />
                );
            })}
            <rect x="1.5" y="7.5" width="61" height="114" rx="4.5" stroke="#C3C9DB" strokeWidth="3" />
        </svg>

    );
};
