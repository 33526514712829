import { FC, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import WelcomeImage from '../../assets/images/welcome-image.png';
import WelcomeFace from '../../assets/images/welcome-face.png';
import styles from './styles.module.css';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { availableEvents } from '../../availableEvents';

export const Welcome: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n, t } = useTranslation();
    const query = useQuery();

    const getEvent = (event: string) => {
        return availableEvents.find((e) => e.name === event);
    };

    useEffect(() => {
        if (query.has('event')) {
            if (query.get('event') === 'none') {
                localStorage.removeItem('event_name');
                localStorage.removeItem('event_rules');

                return;
            }

            const event = getEvent(query.get('event') as string);

            if (event) {
                localStorage.setItem('event_name', event.name);
                localStorage.setItem('event_rules', JSON.stringify(event.rules));
            }
        }
    }, [query]);

    return (
        <div>
            <img className={styles.welcomeImage} src={WelcomeImage} alt="Welcome" />
            <Navbar isResults={false} />
            <div className={styles.container}>
                <h1 className={styles.title}>
                    <Trans i18nKey="welcome.title" components={{ span: <span className="gradient-yellow" /> }} />
                </h1>
                <p className={styles.headTitle}>{t('welcome.headTitle')}</p>
                <p className={styles.description}>{t('welcome.description')}</p>
                <img className={styles.welcomeFace} src={WelcomeFace} alt="Smile" />
                <Link to="/profile">
                    <Button text={t('welcome.startButton')} buttonType="big"  />
                </Link>
            </div>
        </div>
    );
};
